<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-form
      ref="order-form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-row justify-space-between align-center">
          <h2>
            จัดการใบสูตรการผลิต
          </h2>
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูลใบสูตรการผลิต
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="4">
          <gw-drop-file
            v-model="formData.imageURL"
            need-compress
            :limit="1"
            s3-path="/purchases-items"
            caption="คลิกเพื่อเพิ่มรูปภาพ"
            disabled />
        </v-col>
        <v-col
          cols="12"
          md="8">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formData.parentItemNo"
                label="รหัสสินค้า"
                color="secondary"
                dense
                outlined
                hide-details
                disabled />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.description"
                label="ชื่อสินค้า"
                color="secondary"
                dense
                outlined
                hide-details
                disabled />
            </v-col>
            <v-col cols="12">
              <v-btn
                color="secondary"
                :disabled="loading"
                @click="toggleItem('FG')">
                เลือกสินค้า
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูลวัตถุดิบ
          </span>
          <v-divider />
        </v-col>
        <v-col
          v-for="(item, index) in formData.assemblyBOMLines"
          :key="index"
          cols="12">
          <v-row class="pl-6">
            <v-col
              cols="12"
              class="header-line mt-2">
              <span>
                {{ item.itemNo || 'วัตถุดิบใหม่' }}
              </span>
              <v-divider />
            </v-col>
            <v-col
              cols="12"
              md="4">
              <gw-drop-file
                v-model="item.imageURL"
                need-compress
                :limit="1"
                s3-path="/purchases-items"
                caption="คลิกเพื่อเพิ่มรูปภาพ"
                disabled />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <v-row>
                <v-col
                  cols="12"
                  md="6">
                  <v-text-field
                    v-model="item.itemNo"
                    label="รหัสวัตถุดิบ"
                    color="secondary"
                    :rules="textBoxRules"
                    dense
                    outlined
                    hide-details
                    disabled />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <v-select
                    v-model="item.lineType"
                    label="ประเภทรายการ"
                    color="secondary"
                    :rules="textBoxRules"
                    :items="lineTypes"
                    hide-details
                    dense
                    outlined
                    :disabled="loading || !!item.itemNo" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <v-text-field
                    v-model.number="item.quantityPer"
                    label="จำนวนที่สูตรการผลิต"
                    color="secondary"
                    type="number"
                    :rules="numberRules"
                    dense
                    outlined
                    hide-details
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <v-select
                    v-model="item.uom"
                    label="หน่วยของที่สูตรการผลิต"
                    color="secondary"
                    :rules="textBoxRules"
                    :items="UOMs"
                    item-text="text"
                    item-value="value"
                    dense
                    outlined
                    :disabled="loading || !!item.itemNo" />
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <!-- <v-btn
                    class="mr-2"
                    color="secondary"
                    :disabled="loading"
                    @click="toggleItem('RM', index)">
                    เลือกวัตถุดิบ
                  </v-btn> -->
                  <v-btn
                    color="error"
                    :disabled="formData.assemblyBOMLines.length <= 1"
                    @click="removeItem(index)">
                    ลบวัตถุดิบ
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center align-center"
          style="gap: 8px;">
          <v-btn
            color="secondary"
            :disabled="loading"
            @click="toggleItem('RM')">
            เพิ่มวัตถุดิบ
          </v-btn>
          <v-btn
            class="px-8"
            color="success"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <item-list-modal
      v-model="itemDialog"
      :type="type"
      :parent="type === 'FG'"
      :single="type === 'FG'"
      :selected-items="formData.assemblyBOMLines"
      @select="selectedItem($event)"
      @close="toggleItem('RM')" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AssemblyBOMProvider from '@/resources/AssemblyBOMProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import UnitOfMeasuresProvider from '@/resources/UnitOfMeasuresProvider'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import ItemListModal from '../components/ItemListModal.vue'

const AssemblyBOMService = new AssemblyBOMProvider()
const ProductAttributeService = new ProductAttributeProvider()
const UnitOfMeasuresService = new UnitOfMeasuresProvider()

export default {
  components: { ItemListModal },
  data () {
    return {
      loading: false,
      isEdit: false,
      textBoxRules: [
        (v) => !!v || 'Field is require!'
      ],
      numberRules: [
        (v) => (!!v && v > 0) || 'Please enter number more than 0.'
      ],
      UOMs: [],
      productAttributes: {
        brands: [],
        categories: [],
        colors: [],
        size: [],
        tags: [],
        collections: [],
        groupCategories: []
      },
      formData: {
        parentItemNo: '',
        childsItemNo: [],
        description: '',
        imageURL: '',
        brand: '',
        noOfLines: 1,
        assemblyBOMLines: []
      },
      lineTypes: [
        'Comment',
        'G/L Account',
        'Item',
        'Resource',
        'Fixed Asset',
        'Charge (Item)'
      ],
      itemDialog: false,
      type: 'FG'
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return this.mapWarehouse.filter((w) => w.id !== 0).sort((a, b) => a.code.localeCompare(b.code))
    }
  },
  async mounted () {
    await this.getAllAttributes()

    if (this.$route.params?.id) {
      this.isEdit = true
      this.getItem()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    toggleItem (type) {
      this.type = type
      this.itemDialog = !this.itemDialog
    },
    removeItem (index) {
      this.formData.assemblyBOMLines.splice(index, 1)
    },
    getColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.getColor(color.childs, itemsDisabled) : undefined,
        gradientCss: getImageOrGradientCss(color)
      }))
    },
    async getAllAttributes () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await ProductAttributeService.getAllProductAttribute()

        this.productAttributes = {
          colors: Array.isArray(data.colors) ? this.getColor(data.colors) : [],
          brands: Array.isArray(data.brands) ? data.brands : [],
          size: Array.isArray(data.size) ? data.size : [],
          tags: Array.isArray(data.tags) ? data.tags : [],
          categories: Array.isArray(data.categories) ? data.categories : [],
          collections: Array.isArray(data.collections) ? [{ id: null, name: 'ไม่ระบุ' }, ...data.collections] : [],
          groupCategories: Array.isArray(data.groupCategories) ? data.groupCategories : []
        }

        const { data: uom } = await UnitOfMeasuresService.getItems({
          page: 1,
          limit: 9999
        })

        this.UOMs = uom.results.map((r) => ({
          text: r.name,
          value: r.code
        }))
      } catch (error) {
        console.error('getAllAttributes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async getItem () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await AssemblyBOMService.getItemById(this.$route.params.id)

        this.formData = { ...data }
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async onSubmit () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        this.formData.noOfLines = this.formData.assemblyBOMLines.length
        this.formData.assemblyBOMLines = this.formData.assemblyBOMLines.map((item, index) => ({
          ...item,
          sequence: (index + 1) * 10000
        }))

        if (this.isEdit) {
          await AssemblyBOMService.updateItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'แก้ไขใบสูตรการผลิตสำเร็จ',
            type: 'success'
          })
        } else {
          await AssemblyBOMService.createItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'สร้างใบสูตรการผลิตสำเร็จ',
            type: 'success'
          })
        }

        this.$router.push({ name: 'PurchasesAssemblyBOM' })
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    selectedItem (item) {
      if (this.type === 'FG') {
        const childs = this.formData?.childsItemNo || []

        this.formData = {
          ...this.formData,
          parentItemNo: item.model,
          childsItemNo: item.skus.map((v) => {
            const child = childs.find((c) => c.itemNo === v.code)

            return {
              itemNo: v.code,
              bcSync: child?.bcSync || {
                sync: false,
                bcLog: null
              }
            }
          }),
          imageURL: item.imageURL,
          description: item.name,
          brand: item.brand
        }

        this.toggleItem('RM')
      } else {
        this.formData.assemblyBOMLines.push({
          sequence: (this.formData.assemblyBOMLines.length + 1) * 10000,
          lineType: 'Item',
          itemNo: item.itemNo,
          description: item.description,
          uom: item.baseUOM || item.salesUOM || item.purchaseUOM || '',
          imageURL: item.imageURL,
          quantityPer: 0
        })
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
